export const HOME = "/";
export const ABOUT_MJ = "/about-mj";
export const CONTACT = "/contact";
export const PRIVACY_POLICY = "/privacy-policy";
export const PHILOSOPHY = "/philosophy";
export const SUCCESS = `/success`;
export const HAND_SURGERY = `/hand-surgery`;
export const MEDIATION = `/mediation`;
export const GO_TOUCHLESS = `/go-touchless`;
export const AGENCY = `/agency`;
export const EQUINE_SPIRIT = `/equine-spirit`;
export const SHOP = `/shop`;
export const BOOKS_MAGS = `/books-mags`;
export const ZEN = `/zem`;
export const REAL_ESTATE = `/real-estate`;
export const FASHION = `/fashion`;
export const VOICE = `/voice`;
export const SAMSONITE_STORY = `/samsonite-story`;
export const THANKS = `/thanks`;
