import { uid } from "uid";
import {
  HOME,
  ABOUT_MJ,
  PHILOSOPHY,
  HAND_SURGERY,
  MEDIATION,
  GO_TOUCHLESS,
  AGENCY,
  EQUINE_SPIRIT,
  FASHION,
  SHOP,
  VOICE,
  BOOKS_MAGS,
  CONTACT,
} from "../../constants/routes";

export const navigation = [
  { id: uid(), title: "Home", route: HOME },
  {
    id: uid(),
    title: "Mj",
    route: "/mj",
    dropdown: [
      { id: uid(), title: "About MJ", route: ABOUT_MJ },
      { id: uid(), title: "Philosophy", route: PHILOSOPHY },
    ],
  },
  { id: uid(), title: "Hand Surgery", route: HAND_SURGERY },
  { id: uid(), title: "Mediation", route: MEDIATION },
  { id: uid(), title: "Go Touchless", route: GO_TOUCHLESS },
  {
    id: uid(),
    title: "Sports",
    route: "/sports",
    dropdown: [
      { id: uid(), title: "Agency", route: AGENCY },
      { id: uid(), title: "Equine Spirit", route: EQUINE_SPIRIT },
      // { id: uid(), title: "Golf", route: FASHION },
      // { id: uid(), title: "Shop", route: SHOP },
    ],
  },
  { id: uid(), title: "Voice", route: VOICE },
  { id: uid(), title: "Books & Mags", route: BOOKS_MAGS },
  { id: uid(), title: "Contact", route: CONTACT },
];
